<template>
  <v-form v-if="localForm" class="position-relative">
    <v-card-title class="text-h5 textPrimary--text font-weight-semibold">{{
      $t('vendor.onboarding.vendorClassification')
    }}</v-card-title>
    <v-row>
      <v-col :lg="3" :md="6">
        <cz-form-field
          :label="$t('vendor.onboarding.primaryCategory')"
          required
        >
          <validation-provider
            rules="required"
            :name="$t('vendor.onboarding.primaryCategory')"
            v-slot="{ errors }"
          >
            <cz-autocomplete
              v-model="localForm.primaryCategoryId"
              :items="primaryCategories"
              :error-messages="errors"
              item-text="name"
              item-value="_id"
              :placeholder="$t('vendor.onboarding.choosePrimaryCategory')"
              @change="localForm.secondaryCategoryId = []"
              :readonly="readonly"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :lg="9" :md="6">
        <cz-form-field
          :label="$t('vendor.onboarding.secondaryCategory')"
          required
        >
          <validation-provider
            rules="required"
            :name="$t('vendor.onboarding.secondaryCategory')"
            v-slot="{ errors }"
          >
            <cz-input-tags
              v-model="localForm.secondaryCategoryId"
              :items="filteredSecondaryCategories"
              item-color="primary darken-2"
              :error-messages="errors"
              item-text="name"
              chips
              :placeholder="
                !localForm.primaryCategoryId
                  ? $t('vendor.onboarding.choosePrimaryCategoryFirst')
                  : $t('vendor.onboarding.chooseSecondaryCategory')
              "
              item-value="_id"
              :disabled="!localForm.primaryCategoryId"
              :readonly="readonly"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :lg="12" :md="12">
        <cz-form-field :label="$t('vendor.onboarding.vendorDescription')">
          <cz-input
            v-model="localForm.description"
            multi-line
            :rows="6"
            auto-grow
            :max-length="1500"
            :counter="1500"
            :readonly="readonly"
          />
        </cz-form-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  CzFormField,
  CzInput,
  CzAutocomplete,
  CzInputTags
} from '@/components';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
export default {
  name: 'OnBoardingBusinessClassification',
  components: {
    CzFormField,
    CzAutocomplete,
    CzInput,
    CzInputTags
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    primaryCategories: {
      type: Array,
      required: true
    },
    secondaryCategories: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.localForm = cloneDeep(this.value);
  },
  computed: {
    filteredSecondaryCategories() {
      if (!this.localForm.primaryCategoryId) {
        return [];
      }

      return this.secondaryCategories.filter(
        (item) => item.categoryId === this.localForm.primaryCategoryId
      );
    }
  },
  data() {
    return {
      localForm: null
    };
  },
  watch: {
    localForm: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      }
    }
  }
};
</script>

<style></style>
